import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
 
const routes = [
  {
    path: '/homePage',
    name: '展示首页',
    component: () => import("@/views/homePage/indexPage.vue")
  },
  {
    path: '/',
    name: '展示首页',
    redirect: "/homePage"
  }
]
 
const router = new VueRouter({
  routes: routes
})
 
export default router;